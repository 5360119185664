import useTypeList from "./useTypeList";
import "../index.less";
import GlobalConfig from "@src/GlobalConfig";
import { useNavigate, useParams } from "react-router-dom";

const FooterRight = () => {
  const { typeList } = useTypeList();
  const navigator = useNavigate();
  const { lang } = useParams();
  return (
    <div className="footerRight flex justify-around">
      {typeList.map((type: any) => {
        return (
          <div key={type.title}>
            <div className="typeTitle">{type.title}</div>
            {type.list.map((item: any) => {
              return (
                <div
                  className={`typeItem flex items-center ${item.path ? "cursor-pointer" : ""}`}
                  key={item.name}
                  onClick={() => {
                    if (item.path === "develop") {
                      GlobalConfig.pushOpenAPI();
                    } else {
                      navigator(`/${lang}${item.path}`);
                    }
                  }}
                >
                  {item.icon && <div className="iconWrap flex items-center">{item.icon}</div>}
                  {item.name}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default FooterRight;
