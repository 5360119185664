import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import i18n from "@src/Basic/Language/i18n";
import useNavList, {
  INav,
  ISubContent,
} from "@src/Components/InternationalComponents/hooks/useNavList";
import styles from "./index.module.less";

const NavList = () => {
  const { navList } = useNavList();
  const [data, setData] = useState<any[]>([]);
  const navigate = useNavigate();
  const { lang } = useParams();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);

  useEffect(() => {
    setData(navList);
  }, [navList]);

  const handleChevron = (key: string | undefined, chevron: string) => {
    const newData = data.map((item) => {
      if (item.chevron && item.key === key) {
        item.chevron = chevron;
      }
      return item;
    });
    setData(newData);
  };

  const handleNavigate = (item: any) => {
    navigate(`/${lang}${item.path}`);
  };

  const handleTabNav = (item: INav) => {
    if (_.isEmpty(item)) return;

    const { path, key, content } = item;
    handleChevron(key, item.chevron === "up" ? "down" : "up");
    if (!content?.length) {
      navigate(`/${lang || i18Language}${path}`);
    }
  };

  const renderTitleUnionYTitleIcon = (o: INav, item: INav) => {
    return (
      <div className={`flex items-center`}>
        {o.titleIcon && (
          <nav className={`${styles.PCNavSubContentTitleIcon} flex items-center`}>
            {o.titleIcon}
          </nav>
        )}

        {o.title && !o.showDisabled && (
          <nav
            className={`
            ${styles.PCNavTitle} 
            ${o.titleIcon ? `${styles.PCHasTitleIcon}` : null} 
            cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              handleNavigate(o);
              handleChevron(item.key, "down");
            }}
          >
            {o.title}
          </nav>
        )}

        {o.title && o.showDisabled && (
          <nav className={`${styles.PCNavTitle} ${styles.PCNavTitleDisabled} cursor-pointer`}>
            {o.title}
          </nav>
        )}
      </div>
    );
  };

  return (
    <div className={`${styles.PCNavList} flex h-full`}>
      {data.map((item: INav) => {
        return (
          <div
            className="h-full relative"
            key={item.key}
            onMouseEnter={(e) => {
              handleChevron(item.key, "up");
            }}
            onMouseLeave={(e) => {
              handleChevron(item.key, "down");
            }}
            onClick={(e) => {
              handleTabNav(item);
            }}
          >
            {item.chevron === "up" && (
              <div
                className={`${styles.PCNavWrap} flex`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {item?.content?.map((navContent: ISubContent, index: number) => {
                  return (
                    <div className={`${styles.PCNavContent}`} key={index}>
                      {renderTitleUnionYTitleIcon(navContent, item)}

                      {navContent?.list?.map((nav: INav, idx: number) => {
                        return (
                          <div className="flex justify-between">
                            {renderTitleUnionYTitleIcon(nav, item)}
                            {!nav?.showDisabled && <div>&gt;</div>}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
            <div className={`${styles.PCTabTitle} h-full flex items-center cursor-pointer`}>
              <span>{item.title}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NavList;
