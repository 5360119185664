import { IImagesProps } from "@src/Components/InternationalComponents/hooks/useImageLanuage";
import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import { t } from "i18next";
import AreaCode from "@src/assets/international/AreaCode.json";
import { IKVProps } from "@src/Model/IKVProps";
import { Form, Input, Select, Radio, Checkbox, Button } from "antd";
import { ReactNode } from "react";
import _ from "lodash";
import { IUseSelectOptsRes } from "./hooks/useSelectOpts";

export type FieldType = {
  firstName?: string;
  lastName?: string;
  areaCode?: string;
  phoneNumber?: string;
  email?: string;
  businessStatus?: string;
  businessType?: string;
  privacyStatus?: string;
  obtainServiceContent?: string;
  channel?: string;
  postalCode?: string;
  address?: string;
};

interface IIprops extends IImagesProps {
  [key: string]: any;
}

export const renderImageItem = (item: IIprops) => {
  return <img src={item.src} alt={item.alt} className="object-contain w-full h-full" />;
};

export const renderAreaOptions = (option: any) => {
  // 宽度是跟随form select 内容的宽度的变化的
  return (
    <p className="text-black/[0.88] m-0 p-0 box-border">
      {option.data.Country} + {option.data.CountryCode}
    </p>
  );
};

export const handleFilterAreaOption = (input: string, option?: IKVProps) => {
  return (
    String(option?.CountryCode)?.includes(input) ||
    option?.Country?.toLowerCase().includes(input.toLowerCase())
  );
};

const renderLabel = (title: string, isRequired: boolean) => {
  return (
    <div className={`flex items-center gap-[2px] text-[#717171]`}>
      <label>{title}</label>
      {isRequired && <label>*</label>}
    </div>
  );
};

// form表单字段
export const formFirstName = () => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitleName, {
          ns: "InternationalWebsite",
        }),
        true
      )}
      name="firstName"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
  );
};

export const formLastName = () => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitleSurname, {
          ns: "InternationalWebsite",
        }),
        true
      )}
      name="lastName"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
  );
};

export const formAreaCode = () => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitlePhone, {
          ns: "InternationalWebsite",
        }),
        true
      )}
      name="areaCode"
      rules={[{ required: true }]}
    >
      <Select
        options={_.map(AreaCode, (o) => ({
          ...o,
          CountryCodeLabel: `+${o.CountryCode}`,
        }))}
        fieldNames={{ value: "CountryCode", label: "CountryCodeLabel" }}
        optionRender={renderAreaOptions}
        showSearch
        allowClear
        filterOption={handleFilterAreaOption}
        defaultValue={"+34"}
      />
    </Form.Item>
  );
};

export const formPhoneNumber = () => {
  return (
    // label 必填 起到一个占位符的作用 样式对齐
    <Form.Item<FieldType>
      label={renderLabel(" ", false)}
      name="phoneNumber"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
  );
};

export const formEmail = () => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitleEmail, {
          ns: "InternationalWebsite",
        }),
        true
      )}
      name="email"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
  );
};

export const formBusinessStatus = (businessStatusOpts: IUseSelectOptsRes["businessStatusOpts"]) => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitleBusinessStatus, {
          ns: "InternationalWebsite",
        }),
        false
      )}
      name="businessStatus"
      rules={[{ required: true }]}
    >
      <Radio.Group options={businessStatusOpts} />
    </Form.Item>
  );
};

export const formBusinessType = (businessTypeOpts: IUseSelectOptsRes["businessTypeOpts"]) => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitleBusinessType, {
          ns: "InternationalWebsite",
        }),
        true
      )}
      name="businessType"
      rules={[{ required: true }]}
    >
      <Select options={businessTypeOpts} showSearch allowClear />
    </Form.Item>
  );
};

export const formChannel = (channelTypeOpts: IUseSelectOptsRes["channelTypeOpts"]) => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitleChannel, {
          ns: "InternationalWebsite",
        }),
        false
      )}
      name="channel"
    >
      <Select options={channelTypeOpts} showSearch allowClear />
    </Form.Item>
  );
};

export const formObtainServiceContent = () => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.contactFormContainerForTitleHelp, {
          ns: "InternationalWebsite",
        }),
        false
      )}
      name="obtainServiceContent"
    >
      <Input />
    </Form.Item>
  );
};

export const formPrivacyStatus = () => {
  return (
    <Form.Item<FieldType>
      name="privacyStatus"
      valuePropName="checked"
      // rules={[{ required: true }]}
      rules={[
        {
          validator: (_, value) => (value ? Promise.resolve() : Promise.reject()),
        },
      ]}
    >
      <Checkbox>
        {t(InternationalEnum.contactFormContainerForTitleAccept, {
          ns: "InternationalWebsite",
        })}
      </Checkbox>
    </Form.Item>
  );
};

export const formPostalCode = () => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.tarifasFormContainerForTitlePostalCode, {
          ns: "InternationalWebsite",
        }),
        false
      )}
      name="postalCode"
      rules={[{ required: false }]}
    >
      <Input />
    </Form.Item>
  );
};

export const formAddress = () => {
  return (
    <Form.Item<FieldType>
      label={renderLabel(
        t(InternationalEnum.tarifasFormContainerForTitleCity, {
          ns: "InternationalWebsite",
        }),
        false
      )}
      name="address"
      rules={[{ required: false }]}
    >
      <Input />
    </Form.Item>
  );
};

export const formBtnSubmit = () => {
  return (
    <Form.Item>
      <Button type="primary" htmlType="submit">
        {t(InternationalEnum.requestYourPresentationButton, { ns: "InternationalWebsite" })}
      </Button>
    </Form.Item>
  );
};

export const renderDownloadRegionOptions = (option: any) => {
  return <p className="text-[#4340F4] m-0 p-0 box-border">{option.data.value}</p>;
};
export const renderDownloadProductOptions = (option: any) => {
  return <p className="text-[#4340F4] m-0 p-0 box-border">{option.data.value}</p>;
};
