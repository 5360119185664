import CNEnum from "@src/Locales/Constants/CNEnum";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Call } from "./assets/call.svg";
import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as Address } from "./assets/address.svg";
import { ReactComponent as Partner } from "./assets/partner.svg";
import { ReactComponent as Mailbox } from "./assets/mailbox.svg";
import { ReactComponent as Download } from "./assets/download.svg";
import { ReactComponent as Location } from "./assets/location.svg";
import { CommonEnum } from "@src/Locales/Constants/CommonEnum";

const useTypeList = () => {
  const { t, i18n } = useTranslation();
  const typeList = useMemo(() => {
    return [
      {
        title: t(CNEnum.businessType),
        key: "businessType",
        list: [
          {
            name: t(CNEnum.fastFood),
          },
          {
            name: t(CNEnum.dinner),
          },
          {
            name: t(CNEnum.teaDrink),
          },
          {
            name: t(CNEnum.hotPot),
          },
          {
            name: t(CNEnum.weigh),
          },
          {
            name: t(CNEnum.baking),
          },
          {
            name: t(CNEnum.bar),
          },
          {
            name: t(CNEnum.coffee),
          },
          {
            name: t(CNEnum.professionalServices),
          },
        ],
      },
      {
        title: t(CNEnum.resource),
        key: "resource",
        list: [
          {
            name: t(CNEnum.quotation),
          },
          // {
          //   name: t(CNEnum.selectReason),
          // },
          {
            name: t(CNEnum.support),
          },
          {
            name: t(CNEnum.developerPlatform),
            path: "develop",
          },
          // {
          //   name: t(CNEnum.community),
          // },
        ],
      },
      {
        title: t(CNEnum.contact),
        key: "contact",
        list: [
          // {
          //   name: `${t(CNEnum.customerSupport)}：（086）400-6000`,
          //   icon: <Call />,
          // },
          // {
          //   name: `${t(CNEnum.sale)}：（086）470-1673`,
          //   icon: <User />,
          // },
          // {
          //   name: t(CNEnum.branchOffice),
          //   icon: <Address />,
          // },
          // {
          //   name: t(CNEnum.cooperativePartner),
          //   icon: <Partner />,
          // },
          {
            name: `${t(CNEnum.mailbox)}：sales@restosuite.cn`,
            icon: <Mailbox />,
          },
          // {
          //   name: `${t(CommonEnum.globalServiceCentre)}`,
          //   icon: <Location />,
          //   path: "/global-service",
          // },
          {
            name: <span>{t(CNEnum.downloadCenter)}</span>,
            icon: <Download />,
            path: "/download-center",
          },
        ],
      },
    ];
  }, [i18n.language]);

  return {
    typeList,
  };
};

export default useTypeList;
