import { IKVProps } from '@src/Model/IKVProps'
import { makeAutoObservable, } from 'mobx'

class CommonStore {
  isInternational = true
  language = "en_US"
  languageList: IKVProps[] = []

  constructor() {
    makeAutoObservable(this)
    // this.setIsInternational(localStorage.getItem("isInternational") === "1")
  }

  setIsInternational(val: boolean) {
    this.isInternational = val
  }

  setLanguage(val: string) {
    this.language = val
  }

  setLanguageList(data: IKVProps[]) {
    this.languageList = data
    localStorage.setItem("languageList", JSON.stringify(data))
  }
}

export default CommonStore
