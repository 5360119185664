/* eslint-disable no-debugger */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import localeResources from "../../Locales/localResources.json";
import { getTargetModelLanguageResource } from "../../Utils/CommonUtils";
import Emitter from "../../Utils/Emitter";
import GlobalConfig from "@src/GlobalConfig";
import { IKVProps } from "@src/Model/IKVProps";
import LanguageLocalForageStore from "@src/Localforage/LanguageLocalForageStore";

const getSupportLangs = (returnCode = false) => {
  const lngList = localStorage.getItem("languageList") || "";
  let list: IKVProps[] = [
    {
      label: "简体中文",
      value: "zh_CN",
    },
    {
      label: "繁體",
      value: "zh_HK",
    },
    {
      label: "English",
      value: "en_US",
    },
    {
      label: "Español",
      value: "es",
    },
  ];
  try {
    const _list = JSON.parse(lngList);
    if (_list && _list.length) {
      list = _list;
      if (!list.some((i) => i.value === "en_US")) {
        list.push({
          label: "English",
          value: "en_US",
        });
      }
    }
  } catch (err) {
    list = [...list];
  }
  if (returnCode) {
    return list.map((i) => i.value);
  }
  return list;
};

const getValue = (loading = false) => {
  const parseResource = (resource: any) => {
    try {
      const getTargetLanguageResource = (languageKey: string) => {
        return {
          Common: getTargetModelLanguageResource(resource, languageKey),
          InternationalWebsite: getTargetModelLanguageResource(resource, languageKey),
        };
      };

      let langObj = {};
      // 加一个保底
      const list = getSupportLangs();
      if (list && list.length > 0) {
        list.map((item: any) => {
          langObj = {
            ...langObj,
            [item.value]: getTargetLanguageResource(item.value),
          };
        });
      }
      return langObj;
    } catch (error) {
      return localeResources || {};
    }
  };
  return new Promise((resolve) => {
    if (loading) {
      Emitter.emit("languageLoad", true);
    }
    LanguageLocalForageStore.get("language")
      .then((res: IKVProps[]) => {
        resolve(parseResource(res));
      })
      .catch((error) => {
        console.error("i18n-not memory error:", error);
        resolve(parseResource(localeResources));
      });
  });
};

const i18nOptions: IKVProps = {
  debug: process.env.NODE_ENV === "development",
  // TODO dev,test,cn保底使用中文
  fallbackLng: GlobalConfig.isInternational ? "en_US" : "zh_CN",
  resources: localeResources,
  defaultNS: "Common",
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: [...getSupportLangs(true)],
  load: "currentOnly",
  returnEmptyString: false,
  detection: {
    convertDetectedLanguage: (lng: string) => lng.replace("-", "_"),
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nOptions);

export const i18nAddResource = (loading = false, cb?: () => void) => {
  getValue(loading).then((resources: any) => {
    Object.keys(resources).forEach((key: string) => {
      for (const key2 in resources[key]) {
        i18n.addResourceBundle(key, key2, resources[key][key2], true, true);
      }
    });
    Emitter.emit("languageLoad", false);
    cb?.();
  });
};

export const i18nReInit = (supportedLngs: string[]) => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({ ...i18nOptions, supportedLngs })
    .then(() => {
      i18nAddResource();
    });
};

export default i18n;
