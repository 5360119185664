enum DownloadEnum {
    recommendConfig = "cn.recommendConfig",
    minConfiguration = "cn.minConfiguration",
    downloadCenterDesc = "cn.downloadCenterDesc",
    system = "cn.system",
    memory = "cn.memory",
    storage = "cn.storage",
    electronicMenuTab = "cn.electronicMenuTab",
    mealOrder = "cn.mealOrder",

}
export default DownloadEnum;
