import { useTranslation } from "react-i18next";
import GlobalConfig from "@src/GlobalConfig";
import styles from "./index.module.less";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "@src/Basic/Language/i18n";
import NavList from "./NavList";
import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import useImagesLanguage from "@src/Components/InternationalComponents/hooks/useImageLanuage";
import LanguageSwitcher from "@src/Components/LanguageSwitcher";
import HoverButton from "@src/Components/InternationalComponents/HoverButton";

const PCHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { home } = useImagesLanguage();
  const { lang } = useParams();

  const renderContactButton = () => {
    return (
      <div
        className={`${styles.PCNowStart} cursor-pointer`}
        onClick={() => {
          navigate(`/${lang}/contact`);
        }}
      >
        {t(InternationalEnum.applyLinkBtn, { ns: "InternationalWebsite" })}
      </div>
    );
  };

  return (
    <div className={`${styles.PCHeaderWrap}`}>
      <header className={`${styles.PCHeader} m-auto flex items-center justify-between`}>
        <div className={`${styles.PCLogoUnionNavListContainer} flex items-center`}>
          <div
            style={{
              background: `url(${home[0].src}) center center/ contain no-repeat`,
              width: 128,
              height: 24,
              minWidth: 128,
            }}
            onClick={() => {
              navigate("/");
            }}
            className={`${styles.PCLogo}`}
          />
          <NavList />
        </div>

        <div className={`flex justify-around items-center h-full`}>
          <div className={`${styles.PCLanguageSwitch}`}>
            <LanguageSwitcher isCustomSelectPopupClassName={true} />
          </div>
          {renderContactButton()}
        </div>
      </header>
    </div>
  );
};
export default PCHeader;
