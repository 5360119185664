enum HeaderEnum {
    home = "cn.home",
    product = "cn.product",
    industry = "cn.industry",
    customer = "cn.customer",
    study = "cn.study",
    partner = "cn.partner",
    companyIntroduction = "cn.companyIntroduction",
    switchLanguage = "cn.switchLanguage",
    internationalVersion = "cn.internationalVersion",
    contactUs = "cn.contactUs",
    simpleChinese = "cn.simpleChinese",
    traditionalChinese = "cn.traditionalChinese",
    efficientOperation = "cn.efficientOperation",
    largeScreenOrder = "cn.largeScreenOrder",
    cashierSystem = "cn.cashierSystem",
    kitchenProduceSystem = "cn.kitchenProduceSystem",
    handheldOrder = "cn.handheldOrder",
    electronicMenu = "cn.electronicMenu",
    intelligentCalling = "cn.intelligentCalling",
    onlineSmartRestaurant = "cn.onlineSmartRestaurant",
    h5AndApplet = "cn.h5AndApplet",
    aggregateTakeout = "cn.aggregateTakeout",
    onLinePayment = "cn.onLinePayment",
    electronicInvoice = "cn.electronicInvoice",
    taxAdmin = "cn.taxAdmin",
    predetermine = "cn.predetermine",
    intelligentQueue = "cn.intelligentQueue",
    evaluateInteraction = "cn.evaluateInteraction",
    businessPlatformTicket = "cn.businessPlatformTicket",
    smartMarketing = "cn.smartMarketing",
    marketingActivities = "cn.marketingActivities",
    memberManage = "cn.memberManage",
    intelligentStoredValue = "cn.intelligentStoredValue",
    groupBuying = "cn.groupBuying",
    ticket = "cn.ticket",
    memberAnalysis = "cn.memberAnalysis",
    inventory = "cn.inventory",
    procure = "cn.procure",
    cost = "cn.cost",
    delivery = "cn.delivery",
    manageAndDecision = "cn.manageAndDecision",
    numberSpeak = "cn.numberSpeak",
    merchantManagePlatform = "cn.merchantManagePlatform",
    efficientSupportTools = "cn.efficientSupportTools",
    international = "cn.international",
    masterDataManage = "cn.masterDataManage",
    integrationCenter = "cn.integrationCenter",
    chainControl = "cn.chainControl",
    multiOrganizeControl = "cn.multiOrganizeControl",
    chainCoordination = "cn.chainCoordination",
    flexibleOperation = "cn.flexibleOperation",
    efficientManage = "cn.efficientManage",
    supplyChain = "cn.supplyChain",
}
export default HeaderEnum;