import { IKVProps } from "@src/Model/IKVProps"
import localeResources from "../Locales/localResources.json";
import { rootStore } from "@src/Store";

// 在指定模块中获取指定语言的资源
export const getTargetModelLanguageResource = (
    bizModelData: IKVProps[],
    languageKey: string
) => {
    const fallbackLng = rootStore.commonStore.isInternational ? "en_US" : "zh_CN";
    const fallbackResource: any = (localeResources as any)?.[fallbackLng];
    return bizModelData.reduce((result: any, item: any) => {
        // 这里先取远程本语,再取远程保底语言,再取本地保底语言
        if (item?.languageText) {
            if (languageKey in item.languageText) {
                result[item.key] = item.languageText[languageKey];
            } else if (fallbackLng in item.languageText) {
                result[item.key] = item.languageText[fallbackLng];
            }
        } else {
            result[item.key] = fallbackResource?.[item.domain]?.[item.key] || "";
        }

        return result;
    }, {});
};