/* eslint-disable no-debugger */
import { Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { LanguageContext } from "@src/Basic/Language/Provider.tsx";
import { useStore } from "@src/Store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import GlobalConfig from "@src/GlobalConfig";
import i18n from "@src/Basic/Language/i18n";
import styles from "src/Components/InternationalComponents/HomeComponents/index.module.less";

interface IProps {
  isCustomSelectPopupClassName?: boolean;
}

const LanguageSwitcher = ({ isCustomSelectPopupClassName }: IProps) => {
  const { setLanguage, language } = useContext(LanguageContext);
  const [languageDataSource, setLanguageDataSource] = useState<any[]>([]);
  const { commonStore } = useStore();
  const navigator = useNavigate();
  const { pathname, search } = useLocation();
  // const lang = useMemo(() => i18n.language, [i18n.language]);

  // useEffect(() => {
  // !_.isEmpty(languageDataSource) && lang && switchLanguage(lang);
  // }, [languageDataSource, lang, language]);

  useEffect(() => {
    try {
      const allSupportLanguages = localStorage.getItem("languageList");
      if (allSupportLanguages) {
        setLanguageDataSource(JSON.parse(allSupportLanguages));
      }
    } catch (error) {
      //
    }
  }, []);

  const handleNavigateParam = (targetLang: string) => {
    if (_.includes(["zh_CN", "zh_HK"], targetLang)) {
      // 跳到中文版
      GlobalConfig.pushToCN();
    }
  };

  const switchLanguage = (targetLang: string) => {
    setLanguage(targetLang);
    commonStore.setLanguage(targetLang);
    handleNavigateParam(targetLang);
  };

  const handleOnChange = (value: string) => {
    switchLanguage(value);
  };

  return (
    <div className="mx-[24px]">
      <Select
        className="w-[100px]"
        value={language}
        onChange={handleOnChange}
        options={languageDataSource}
        {...(isCustomSelectPopupClassName
          ? { popupClassName: `${styles.PCCustomSelectPopup}` }
          : null)}
      ></Select>
    </div>
  );
};
export default LanguageSwitcher;
