import { useMemo, useState, useContext, useEffect } from "react";
import { ReactComponent as Internet } from "@src/assets/CN/svg/internet.svg";
import { useTranslation } from "react-i18next";
import CNEnum from "@src/Locales/Constants/CNEnum";
import { LanguageContext } from "@src/Basic/Language/Provider.tsx";
import { useStore } from "@src/Store";
import GlobalConfig from "@src/GlobalConfig";
import "./index.less";

const SwitchLanguage = () => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { setLanguage, language } = useContext(LanguageContext);
  const [languageDataSource, setLanguageDataSource] = useState<any[]>([]);
  const { commonStore } = useStore();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    try {
      const allSupportLanguages = localStorage.getItem("languageList");
      if (allSupportLanguages) {
        setLanguageDataSource(JSON.parse(allSupportLanguages));
      }
    } catch (error) {
      //
    }
  }, [i18n.language]);

  const switchLanguage = (targetLang: string) => {
    setLanguage(targetLang);
    commonStore.setLanguage(targetLang);
    setIsHovered(false);
  };

  const handleOnChange = (value: string, isForeign = false) => {
    switchLanguage(value);
    if (isForeign) {
      GlobalConfig.pushToInternational();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="flex items-center relative cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Internet />
      <span className="ml-[4px]">{t(CNEnum.switchLanguage)}</span>
      {isHovered && (
        <div className="absolute switchLanguage">
          {languageDataSource.map((item) => {
            return (
              <div
                className="languageItem"
                key={item.value}
                onClick={() => {
                  handleOnChange(item.value, item.isForeign);
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default SwitchLanguage;
